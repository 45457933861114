import { Avatar, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

import defaultAvatarImage from '../../images/plant.png'
import useStyles from './styles'

export const NotificationCard = ({ content_data, action_url }) => {
  const classes = useStyles()

  return (
    <div className={classes.notification}>
      {action_url?.length ? (
        <Link
          className={classes.link}
          to={`${action_url}`}
        >
          <Avatar
            src={content_data?.thumbnail || defaultAvatarImage}
            variant="square"
          />
          <div className={classes.content_data}>
            <Typography
              className={classes.notificationTitle}
              variant="h6"
              color="textSecondary"
            >
              {content_data.title.value}
            </Typography>
            <Typography
              className={classes.notificationText}
              variant="body2"
              color="textSecondary"
            >
              {content_data.text.value}
            </Typography>
          </div>
        </Link>
      ) : (
        <>
          <Avatar
            src={content_data?.thumbnail || defaultAvatarImage}
            variant="square"
          />
          <div className={classes.content_data}>
            <Typography
              className={classes.notificationTitle}
              variant="h6"
              color="textSecondary"
            >
              {content_data.title.value}
            </Typography>
            <Typography
              className={classes.notificationText}
              variant="body2"
              color="textSecondary"
            >
              {content_data.text.value}
            </Typography>
          </div>
        </>
      )}
    </div>
  )
}
