import moment from 'moment'
import { useEffect, useState } from 'react'

import { useUserData } from '../../context/UserContext'
import { db } from '../../firebase/database'

export const useGetNotifications = () => {
  const { uid } = useUserData()
  const [notifications, setNotifications] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const oneMonthAgo = moment().subtract(1, 'month').unix()

  useEffect(() => {
    setIsLoading(true)
    uid &&
      db
        .collection('notifications')
        .where('show', '==', true)
        .where('user', '==', uid)
        .where('timestamp', '>=', oneMonthAgo)
        .onSnapshot((snapshot) => {
          const notifications = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          setNotifications(notifications)
          setIsLoading(false)
        })
  }, [])

  return [notifications?.sort((a, b) => b.timestamp - a.timestamp), isLoading]
}
