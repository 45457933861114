import { alpha } from '@material-ui/core/styles/colorManipulator'
import { makeStyles } from '@material-ui/styles'

import config from '../../config/config'

export default makeStyles((theme) => ({
  notificationWrapper: {
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },

  logotype: {
    color: 'white',
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: 18,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  badge: {
    '& .MuiBadge-badge': {
      color: 'white',
    },
  },
  storeNameContainer: {
    cursor: 'pointer',
    overflow: 'hidden',
  },
  storeName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  logo: {
    height: '42px',
    // whiteSpace: "nowrap",
    cursor: 'pointer',
    [theme.breakpoints.down(900)]: {
      marginLeft: theme.spacing(0),
      height: config.plant_web_view ? '15px' : '30px',
    },
  },
  login: {
    marginLeft: theme.spacing(2.5),
  },
  appBar: {
    width: '100vw',
    zIndex: 99,
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarMenu: {
    width: '100vw',
    zIndex: theme.zIndex.drawer + 2,
    top: 58,
    minHeight: 47,
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: 'auto',
    width: 'min(100vw, 1450px)',
    // [theme.breakpoints.down(376)]: {
    //   paddingLeft: 0,
    //   paddingRight: 0,
    //   // right: 3,
    // },
    [theme.breakpoints.down(900)]: {
      paddingLeft: 0,
      paddingRight: 0,
      // right: 3,
    },
  },
  firstRow: {
    // [theme.breakpoints.down("xs")]: {
    height: '56px',
    display: 'flex',
    // "position": "absolute",
    alignItems: 'center',
    width: 'stretch',
    top: '0px',
    paddingRight: '28px',
    // },
  },
  secondRow: {
    [theme.breakpoints.down('xs')]: {
      height: '56px',
      display: 'flex',
      alignItems: 'center',
      width: 'stretch',
      padding: '5px',
    },
  },
  hide: {
    display: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: 6,
    paddingLeft: theme.spacing(2.5),
    width: 36,
    backgroundColor: alpha(theme.palette.common.black, 0),
    transition: theme.transitions.create(['background-color', 'width']),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: alpha(theme.palette.common.black, 0.08),
    },
  },
  searchFocused: {
    backgroundColor: alpha(theme.palette.common.black, 0.08),
    width: '100%',

    [theme.breakpoints.up('600')]: {
      // width: 250,
      maxWidth: 'min(calc(100% - 670px),30rem)',
      // marginRight: "30px",
      minWidth: '200px',
    },
  },
  searchFocusedMinimized: {
    width: 'calc(100% - 150px)',
  },
  searchIcon: {
    width: 36,
    right: 0,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: theme.transitions.create('right'),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  searchIconOpened: {
    right: theme.spacing(1.25),
    zIndex: 10,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    height: 36,
    padding: 0,
    paddingRight: 36 + theme.spacing(1.25),
    width: '100%',
  },
  showProducts: {
    marginLeft: '10px',
    backgroundColor: '#c3e6e6',
    color: 'black',
    '&:hover': {
      backgroundColor: '#dcf1f1',
    },
    [theme.breakpoints.between(600, 723)]: {
      display: 'none',
    },
    [theme.breakpoints.up('600')]: {
      marginRight: '10px',
    },
  },
  nav_global_location_slot: {
    backgroundColor: '#c3e6e6',
    color: 'black',
    '&:hover': {
      backgroundColor: '#dcf1f1',
    },
  },
  messageContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerMenu: {
    marginTop: theme.spacing(7),
    padding: '5px',
  },

  headerMenuList: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerMenuItem: {
    '&:hover, &:focus': {
      backgroundColor: '#ccc',
      color: 'black',
    },
  },
  headerMenuButton: {
    marginLeft: theme.spacing(1.2),
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
  },
  headerIcon: {
    fontSize: 28,
    color: 'black', //"rgba(255, 255, 255, 0.35)",
  },
  headerIconCollapse: {
    color: 'black',
  },
  profileMenu: {
    minWidth: 265,
  },
  profileMenuUser: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    //padding: theme.spacing(2),
  },
  profileMenuItem: {
    color: theme.palette.text.hint,
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: '#333',
  },
  profileMenuLink: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px auto 0px',
    background: 'rgb(51, 51, 51)',
    color: 'rgb(255, 255, 255)',
    width: '100%',
    fontSize: '15px',
    //padding:'5px',
    alignItems: 'center',
    marginTop: '8px',
    marginBottom: '-8px',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      background: '#444',
    },
  },
  messageNotification: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.background.light,
    },
  },
  hoverMenu: {
    '&:hover': {
      backgroundColor: theme.palette.background.light,
    },
  },

  messageNotificationSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  messageNotificationBodySide: {
    alignItems: 'flex-start',
    marginRight: 0,
  },
  sendMessageButton: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: 'none',
  },
  sendButtonIcon: {
    marginLeft: theme.spacing(2),
  },
  tabs: {
    marginBottom: '-15px',
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
}))
