import 'react-image-lightbox/style.css'

import React, { useEffect } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import { useUserData } from '../context/UserContext'
import { FieldValue, getFirebaseToken, updateDoc } from '../firebase/database'
// components
import Layout from './Layout'

export default function App() {
  const { uid, fcmTokens } = useUserData()

  useEffect(() => {
    getFirebaseToken().then((token) => {
      if (fcmTokens?.includes(token)) return

      uid && token &&
        updateDoc({
          indexName: 'users',
          id: uid,
          hit: {
            fcmTokens: FieldValue.arrayUnion(token),
          },
        })
    })
  }, [])

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          component={({ location }) => (
            <Redirect
              to={{
                ...location,
                pathname: '/app/homepage',
              }}
            />
          )}
        />
        <Route
          exact
          path="/app"
          component={({ location }) => (
            <Redirect
              to={{
                ...location,
                pathname: '/app/homepage',
              }}
            />
          )}
        />
        <Route
          path="/app"
          render={Layout}
        />
        <Route
          component={({ location }) => (
            <Redirect
              to={{
                ...location,
                pathname: '/app/homepage',
              }}
            />
          )}
        />
        {/*<Route component={Error} />*/}
      </Switch>
    </BrowserRouter>
  )
}

// #######################################################################

//   function PrivateRoute({ component, LazyComponent, ...rest }) {
//
//
//     return (
//       <Route
//         {...rest}
//         render={props =>
//           (
//               component ? (React.createElement(component, props)) : (
//                   <Suspense fallback={<LoadingPage/>}>
//                   <LazyComponent {...props} />
//               </Suspense> )
//           )
//         }
//       />
//     );
//   }
// }
